@import "~pure-react-carousel/dist/react-carousel.es.css";

.carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;

  .carousel, .carousel__slider {
    max-height: 300px;
  
      img {
        max-height: 300px;
        display: block;
        margin: 0 auto;
      }
  
    &:focus, :focus {
      outline: none;
    }
  }  
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;800&display=swap');

$family-sans-serif: 'Montserrat', sans-serif;;

$navbar-box-shadow-color: transparent;
$navbar-item-hover-background-color: transparent;
$navbar-item-hover-color: var(--primary);

$button-color: var(--primary);
$button-border-color: var(--primary);
$button-focus-border-color: var(--primary);
$button-text-color: var(--primary);
$button-hover-color: var(--primary);
$button-hover-border-color: var(--primary);

@import "~bulma/bulma";

:root {
  --primary: #051535;
}

::selection {
  background: var(--primary);
  color: #fff;
}

* {
  box-sizing: border-box;
  scrollbar-color: transparent transparent;
  scrollbar-width: 0px;
}

*::-webkit-scrollbar {
  width: 0;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: transparent;
  border: none;
}

* {
  -ms-overflow-style: none;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  .main-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
}

a {
  font-weight: 800;
}

.title {
  font-weight: 600;
  font-size: 1.25rem;
  color: var(--primary);
}

p {
  color: var(--primary);
}

.button {
  border-radius: 0;
}

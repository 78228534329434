#about-page {
    display: flex;
    align-items: center;
    padding: 1.5rem;

    .is-medium {
        text-align: center;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 3rem;
        font-weight: 600;
    }

    .is-split {
        columns: auto 2;
        column-gap: 50px;
    }

    p {
        color: black;
    }
}

#product {
    display: flex;
    align-items: center;
    padding: 20px;
    width: 100%;

    .product-container {
        display: flex;
        width: 100%;

        // .images, .text {
        //     width: 50%;
        // }

        .images {
            padding: 10px;

            .image {
                display: block!important;
                width: 75%;
                margin: 10px auto;
            }
        }

        .description {
            p + .button {
                margin-top: 10px;
            }
        }
    }
}

#store {
    display: flex;
    align-items: center;

    .products-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0;
        margin: 0 10px;
    
        .product {
            width: calc(25% - 47px);
            margin: 20px;
            p {
                font-weight: 600;
            }
        }
    }
}

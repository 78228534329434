.navbar {
  .navbar-menu {
    justify-content: space-between;

    .navbar-start,
    .navbar-end {
      margin: 0;
    }
  }

  a {
    color: var(--primary);
    text-decoration: none;
    letter-spacing: 0.5px;

    

    span {
      text-transform: uppercase;
      transition: all 0.4s ease;
      border-bottom: 1px solid transparent;
      border-top: 1px solid transparent;

      &:hover {
        border-bottom-color: var(--primary);
      }
    }

    &.selected span {
      border-bottom-color: var(--primary);
    }
  }

  svg {
    width: 1rem;
    height: 1rem;
  }

  .navbar-end {
    .navbar-item {
      padding: 0.5rem 0.25rem;
    }
  }
}

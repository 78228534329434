.footer {
    background-color: white;
    padding: 23px 0px;
    text-align: left;
    text-transform: uppercase;
    font-size: 13px;

    .partners {
        text-align: right;

        img {
            height: 50px;
            width: auto;
            margin: 0 10px;
        }
    }

    a {
        color: var(--primary);
        text-decoration: none;
      }
}
